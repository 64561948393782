<template>
  <div class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(n, i) in space.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame dark">
          <div class="line"
               style="padding:24px 32px">
            <el-button-group>
              <el-button :type="filterType == n.value?'primary':''"
                         v-for="(n,i) in typeOption"
                         :key="i"
                         @click="chooseType(n)">{{n.name}}</el-button>
            </el-button-group>
          </div>
          <div class="line">
            <el-timeline>
              <el-timeline-item :timestamp="n.createDate"
                                :color="n.color"
                                placement="top"
                                v-for="(n,i) in list"
                                :key="i"
                                >
                <el-card>
                  <div class="opt-layet">
                    <img :src="n.head"
                         alt="">
                    <h4>{{n.userName}}</h4>
                    <p>{{n.content}}&nbsp;&nbsp; 在 &nbsp;<span @click="jumpSpaceDetail(n.spaceId)">{{n.routeName}}</span></p>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <empty v-if="list.length == 0" info="空间"
               style="height:70vh"></empty>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import _ from 'underscore';
import Empty from 'components/common/forms/Empty';
import { jumpRoute } from 'common/authBtn';
export default {
  name: "OperationLog",
  components: {
    Empty
  },
  setup() {
    let filterType = ref(1), list = ref([]), title = ref(''), space = ref({}),
    usersOptions = ref([]), loading = ref(true);
    const typeOption = reactive([
      {
        name: "全部类型",
        value: 1
      }, {
        name: "变更",
        value: 2
      }, {
        name: "成员",
        value: 3
      }
    ]);
    const roleList = ref([
          {
            id: 1,
            name: '拥有者'
          },{
            id: 2,
            name: '管理员'
          },
          {
            id: 3,
            name: '配置人员'
          },{
            id: 4,
            name: '开发人员'
          },{
            id: 5,
            name: '游客'
          }
        ]);
    return {
      loading,
      filterType,
      list,
      title,
      space,
      usersOptions,
      roleList,
      typeOption
    }
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    getDetail() {
      let self = this;
      self.$api.sp.getDetailById().then((res) => {
        if(res) {
          self.title = res.name;
          self.space = res;
          self.getDataFn();
        }
      })
    },
    getAllUsers() {
      let self = this;
      self.$api.auth.selectAllUser().then((res) => {
        self.loading = false;
        self.usersOptions = res;
        self.getDetail();
      })
    },
    getDataFn() {
      let self = this;
      self.$api.sp.getSpaceByType({type: self.filterType, spaceId: self.space.id}).then((res) => {
        self.list = res;
        _.each(self.list, (v) => {
          v.routeName = '';
          _.each(v.routes, (r) => {
            v.routeName = v.routeName + '/' + r.name;
          })
          v.routeName = v.routeName.substring(1, v.routeName.length);
          v.head = require('assets/images/frame/default-head.svg');
          let user = _.filter(self.usersOptions, (u) => {
            return v.createBy == u.id;
          })
          if(user && user.length > 0) {
            v.userName = user[0].nickname;
          }
          v = self.setLogContent(v);
        })
      })
    },
    setLogContent (obj) {
      let content = '', self = this;
      switch (obj.sgroup) {
        //空间
        case 1:
          switch (obj.operation) {
            case 1:
              content = '新增空间' + obj.name;
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              
              if(obj.oldName != '-1') {
                content = "编辑空间:" + obj.oldName + "修改为" + obj.name;
              }else 
                content = "编辑空间属性"
              // if(obj.oldName != '-1'&& obj.oldDescription != '-1' && obj.oldPic != '-1')
              //   content = '编辑空间名称和描述： ' + obj.oldName + "修改为" + obj.name + " /n" + obj.oldDescription + "修改为" + obj.description + " /n"
              //   + "更换图片" + obj.oldPic +  "为" + obj.pic;
              // else if(obj.oldName != '-1' && obj.oldDescription != '-1' && obj.oldPic == '-1')
              //   content = '编辑空间名称: ' + obj.oldName + "修改为" + obj.name + " /n" + obj.oldDescription + "修改为" + obj.description;
              // else if(obj.oldDescription != '-1')
              //   content = '编辑空间描述: ' + obj.oldDescription + "修改为" + obj.description;

              obj.color = 'rgba(30, 170, 255, 1)';
              break;
            case 3:
              content = '删除空间' + obj.name;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
        //成员
        case 2:
          let userName = '', roleName = '';
          let user = _.filter(self.usersOptions, (u) => {
            return u.id == obj.userId;
          })
          if(user && user.length > 0) {
            userName = user[0].nickname;
          }
          let role = _.filter(self.roleList, (r) => {
            return r.id == obj.roleId;
          })
          if(role && role.length > 0) {
            roleName = role[0].name;
          }
          switch (obj.operation) {
            case 1:
              content = '邀请' + userName + "加入空间，角色为" + roleName; 
              obj.color = 'rgba(55, 250, 200, 1)';
              break;
            case 2:
              let oldRoleName = '';
              let oldRole = _.filter(self.roleList, (r) => {
                return r.id == obj.oldRoleId;
              })
              if(oldRole && oldRole.length > 0) {
                oldRoleName = oldRole[0].name;
              }
              if(obj.oldRole != '-1'&& obj.oldExpireDate)
                content = '编辑成员: ' + userName + " 的角色由"+ oldRoleName +"修改为" + roleName + " \n" + "过期时间修改为" + obj.oldExpireDate;
              else if(obj.oldRole != '-1')
                 content = '编辑成员: ' + userName + " 的角色由"+ oldRoleName +"修改为" + roleName;
              else 
                content = '编辑成员: ' + userName + " 过期时间修改为" + obj.oldExpireDate;

              obj.color = 'rgba(30, 170, 255, 1)';
              break;
            case 3:
              content = '删除成员' + userName;
              obj.color = 'rgba(250, 115, 0, 1)';
              break;
            default:
              break;
          }
          break;
        case 3:
          
          break;
        default:
          break;
      }
      obj.content = content;
      return obj;
    },
    chooseType (type) {
      this.filterType = type.value;
      this.getDataFn();
    },
    jumpSpaceDetail (spaceId) {
      let self = this;
      self.$api.sp.setCurrentSpace({id: spaceId}).then(res => {
            self.$router.push('/Main/SpaceDetail');
        })
    },
    jumpSpace(n) {
      let self = this;
      if(self.space.id != n.id) {
        jumpRoute(self, n).then(() => {
          self.getDetail();
        })
        // self.$api.sp.setCurrentSpace({id: n.id}).then(res => {
        //     self.getDetail();
        // }) 
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.opt-layet {
  width: 100%;
  padding: 0 0 0 50px;
  display: inline-block;

  img {
    width: 40px;
    height: 40px;
    background: #ddd;
    border-radius: 50%;
    float: left;
    margin: 0 -50px;
  }

  h4,
  p {
    float: left;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    line-height: 1;

    &:hover {
      color: rgba(115, 200, 255, 1);
    }
  }

  p {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
    line-height: 1.4;

    span {
      cursor: pointer;
      color: rgba(30, 170, 255, 1);

      &:hover {
        color: rgba(115, 200, 255, 1);
      }
    }
  }
}
</style>